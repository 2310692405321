@import "../styles/variables.scss";


.contact a:hover{
   padding-top: 1rem;
   color: rgb(182, 255, 249);
}

.contact a{
    padding-top: 1rem;
    color:rgb(238, 172, 255);
}

.contact{
    padding-top: 0rem;
}