@import "../styles/variables.scss";


.header {

        font-family: $Overpass;
        font-size: 1.3rem;
        font-weight: 300;
        letter-spacing: 2px;
        background: $light;
        width: 100%;
        height: $headerHeight;
        z-index: 99;

}



.item_heading {
    display: inline-block;
    position: relative;
    color: $dark;
    font-size: 18px;
    font-family: $Nexa;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;

  }
  
  .item_heading::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $dark;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .item_heading:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }


.header > .container{
    margin: 0 0;
  
}


.header > .container > .navigation  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $headerHeight;
    position: relative;
    background: var(--white);
    padding-left: 2rem;
    padding-right: 2rem;
 
    }

.nav_list{
        display: inline-flex;
        gap: 2rem;
        align-items: center;
        text-align: center;
        margin: 0 3rem;

        font-weight: lighter;  
        letter-spacing: 1px;
}

.nav_link{
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-family: $Overpass;
    font-size: 1.1rem;
    font-weight: 300;
    font-family: $Overpass;
   
}


.toggle {
    cursor: pointer;
    display: none;
}

.animation {
    display: inline-block;
    position: relative;
    color: $dark;
  }
  
  .animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $dark;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }




// .dropdown.open {
//     clip-path: polygon(0 0, 100% 0, 100% 102%, 0 102%);
//   }

.nav_action {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-top: 0.75rem;
    font-size: 1.1rem;
    letter-spacing: normal;
    font-weight: lighter;
    font-family: $Overpass;
    text-transform: uppercase;
   
}
.dropdown {
    position: absolute;
    top: $headerHeight;
    left: 0;
    width: 100%;
    background:$light;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all 0.5s ease-in;
    border-bottom: 0.5rem solid;
    border-left: 0px;
    border-right: 0px;
    border-image: linear-gradient(45deg, rgb(179, 3, 233), rgb(66, 228, 250)) 1;
    z-index: 999;
    text-align: left;
}


.dropdowninner {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 0.7fr));
    background-color: $light;
    padding-left: 8rem;
    padding-right: 2.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
   
}

.item_list_info{
    margin-top: -1.5rem;
    margin-right: 5rem;
    letter-spacing: normal;
}
      
.btn_connect{
        display: inline-flex;
        cursor: pointer;
        font-size: 1.1rem;
        font-weight: 300;
        font-family: $Overpass;
        background-color: $dark;
        transition: 0.3s ease all;
        outline: none;
        padding: 0.75rem 1.25rem;
       
      
}

.btn_connect:hover{
    background: rgba($light, 0.1);
    color: $light;
    border: 2px solid black;
    font-weight: 500;
}

.header .container .navigation .isMenu{
    transform: translate(100%);
  }



ul{
	list-style: none;
	margin:0;
	padding:0;
}

.logo_mobile{
    display: none;
}
.logo_desk{
    width: 250px;
}
.logo a{
    text-decoration: none;
}

.hcontact{
    letter-spacing: normal;
    font-weight: lighter;
    margin-top: -1.5rem;
    text-decoration: none;
}

.hcontact a:hover{
    color:$dark;
}

.nav_menu.active {
    clip-path: polygon(0 0, 100% 0, 100% 102%, 0 102%);
}


@media (max-width:1400px) {

    .header > .container > .navigation  {

        padding-left: 0.5rem;
        padding-right: 1rem;
        margin-left: -2rem;
            display: flex;
            align-items: left;
            justify-content: space-between;
            height: $headerHeight;
            position: relative;
          
  
         
            
 
     
        }

    .dropdown.open{
        display: block;
    }
        
    

    .item_heading{
        font-size: 1rem;
        
    }
    .item_list_info{
       
            margin-right: 0.2rem;
            letter-spacing: normal;
            padding-top: 0.8rem;
            font-size: 0.9rem;
        }
    .toggle {
        display: block;
        z-index: 99999;
        color: $dark;
    }

    .logo img{
       
        width: 200px;
        padding-top: 3.5rem;
        padding-left: 2rem;
    }

    .hcontact{
        margin-top: -2.4rem;
    }
    .hcontact a{
        font-size: 18px;
        margin-bottom: -1rem;
    }
    // .nav_list_menu:hover .dropdown {
    //    display: none;
    // }
.dropdown{
    display: none;
}
.nav_list_menu:hover .dropdown {
    clip-path: polygon(0 0, 100% 0, 100% 102%, 0 102%);

}
    .nav_menu {
        top: 0;
        right: 100%;
        bottom: 0;
        width: 100%;
        height: 100vh;
        position: fixed;
        background: rgba($light, 0.9);
        backdrop-filter: blur(2px);
        transform: translate(0);
        transition: 0.3s ease transform;
        z-index: 1000;
        color: $dark;
       
    }
    
b

    .nav_menu.active {
        display: block;
        font-family: $Nexa;
    }

    // .dropdowninner.active {
    //     display: block;
    //     font-family: $Nexa;
    // }

    // .dropdowninner.active:hover {
    //     display: none;
    //     font-family: $Nexa;
    // }

//    .nav_menu:active:hover {
//         display: none;
//     }
    .nav_list {
        display: block;
        margin: 2rem 0;
        text-align: left;
    }
    .nav_link {
        padding: 0 0rem;
      
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        align-items: left;
    }


.dropdowninner{
    padding-left: 3rem;
}
    // .dropdowninner {
    //     padding-left: 0rem;  
    //     padding-top: 2rem;
      
    //     background: transparent;
    //     padding-bottom: -1rem;
    //     padding-right: 0rem;
    // }
    // .dropdownitem{
    //     padding-bottom: 1rem;
    //     padding-right: 1rem;
    //     margin-left: -2rem;
    // }
    // .dropdown {
    //     top: 0;    .animation {
    //         display: inline-block;
    //         position: relative;
    //         color: $dark;
    //       }
          
    //       .animation::after {
    //         content: '';
    //         position: absolute;
    //         width: 100%;
    //         transform: scaleX(0);
    //         height: 2px;
    //         bottom: 0;
    //         left: 0;
    //         background-color: $dark;
    //         transform-origin: bottom right;
    //         transition: transform 0.25s ease-out;
    //       }
          
    //       .animation:hover::after {
    //         transform: scaleX(1);
    //         transform-origin: bottom left;
    //       }
    //     position: relative;
    //     clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    //     box-shadow: none;
    //     height: 0;
    //     display: block;
    //     text-align: start;
    //     padding-left: 3rem;
    //     transition: all 0.5s ease-in;
    //     background: transparent;
    //     padding-right: 0rem;
    // }
    // .nav_list_menu:hover .dropdown {
    //     height: 100%;
    //     transition: all 0.5s ease-in;
    // }


    .btn_connect{
        display: none;
    }
}

@media (max-width:365px) {
    .logo-img {
        width: 10rem;
    }
    .btn,
    .btn-primary {
        padding: 0.4rem 1rem;
    }
}

@media(hover:hover){
    .nav_list_menu:hover .dropdown {
        clip-path: polygon(0 0, 100% 0, 100% 102%, 0 102%);
    
    }
}