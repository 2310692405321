@import "../styles/variables.scss";

.header {
  padding-right: 0;
  padding-left: 0;
  font-family: $Overpass;
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: 2px;
  background: $light;
  width: 100%;
  height: $headerHeight;
  display: block;
	width: 100%;
	position: relative;
	z-index: 99;
	padding:15px;
  @include breakpoint(md){
    padding: 0 3rem;
  }

  transition: 0.3s ease all;
}

ul{
	list-style: none;
	margin:0;
	padding:0;
}
a{
	text-decoration: none;
}

.header > .content  {
    overflow: hidden;
    color: $dark;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 100;
}

.header > .content > .logo {
  width: 250px;
}
.header > .content > .logo > a{
      font-family: $Nexa;
      font-size: 2rem;
      font-weight: 700;
      text-decoration: none;
      color: $dark;
    }

.header > .content >.menu {
      top: 0;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 100vh;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: rgba($dark, 0.9);
      backdrop-filter: blur(2px);
      transform: translate(0);
      transition: 0.3s ease transform;
     
      @include breakpoint(md){
        transform: none;
        flex-direction: row;
        background: transparent;
        width: auto;
        height: 100%;
        position: static;
      }

    }


.header > .content > .menu > .menumain > li {
      display: inline-flex;
      list-style-type: none;
    }
.header > .content > .menu > .menumain > li a {
      color: $dark;
      font-size: 1.2rem;
      font-weight: 400;
      text-decoration: none;
      margin: 80px;
      position: relative;
      opacity: 0.9;
  }
  
  .header > .content > .menu > .menumain > li a:hover {
      opacity: 1;
  }
  
  .header > .content > .menu > .menumain > li a::before {
      content:"";
      position:absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      background: $dark;
      transition: all .45s;
  }

  .header > .content > .menu > .menumain >  li a:hover::before {
    width: 100%;
  }



  .header > .content > .menu > .btn_connect {
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 300;
    font-family: inherit;
    background-color: $dark;
    color: black;
    border: 1px solid rgb(206, 206, 19);
    transition: 0.3s ease all;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: 0px;

  }

  .header > .content > .menu > .btn_connect:hover{
    border-color: $light;
    background: rgba($light, 0.1);
    color: $light;
  }

  
  .header > .content > .menu > .btn_connect:active{
    border-color: $dark;
    background: linear-gradient(rgba($primary,0.8), rgba($primary, 0.3));
    color: $light;

  }

  .header > .content > .isMenu{
    transform: translate(100%);
  }

  .header > .content > .toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s ease all;
    position: relative;

    @include breakpoint(md){
      display: none;
    }
  }

  .header > .content > .toggle2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 2rem;
    transition: 0.3s ease all;
    position: relative;

    @include breakpoint(md){
      display: none;
    }
  }

  .header > .content > .toggle:hover {
    color: $primary;
  }

  @media(max-width: 1400px){
    .header > .content > .menu > .menumain{
      position: absolute;
      display: flex;
      flex-direction: column;
      color: $dark;
      margin: 10px;
      padding-top: 20px;
      top: 60px;
      z-index:3;
    }

    .header > .content > .menu > .menumain > a{
      color: $light;
      margin: 10px;
      padding-top: 20px;
      align-items: flex-start;
    }

    .header > .content > .menu > .menumain > a:hover {
      opacity: 1;
  }
  
  .header > .content > .menu > .menumain > a::before {
      content:"";
      position:absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      background: $light;
      transition: all .45s;
  }

  .header > .content > .menu > .menumain >  a:hover::before {
    width: 100%;
  }

  }

  