@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass+Mono:wght@300;400;500;600;700&display=swap');
$Poppins:'Poppins', sans-serif;
$Poppins_italic: 'Poppins', italic;
$dark: rgb(29,29,29);
$light: #fff;
$primary: rgb(29,29,29);
$bg: rgb(244,244,255);
$contrast: rgb(28, 197, 209);
$Overpass: 'Overpass Mono', monospace;
//spacing
$spacing-md: 16px;
$spacing-lg: 32px;

//border-radius
$borderRadius: 12px;

$headerHeight: 100px;

@mixin breakpoint($point){
    @if $point == md {
        //768px
        @media (min-width: 88em){
            @content;
        }
    }
}



   @font-face {
    font-family: 'nexa_boldregular';
    font-weight: 500;
    font-style: bold;
    src: url('../fonts/nexa_boldfree_macroman/Nexa_Free_Bold-webfont.eot');
    src: url('../fonts/nexa_boldfree_macroman/Nexa_Free_Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/nexa_boldfree_macroman/Nexa_Free_Bold-webfont.woff2') format('woff2'), url('../fonts/nexa_boldfree_macroman/Nexa_Free_Bold-webfont.woff') format('woff'), url('../fonts/nexa_boldfree_macroman/Nexa_Free_Bold-webfont.ttf') format('truetype'), url('../fonts/nexa_boldfree_macroman/Nexa_Free_Bold-webfont.svg#nexa_boldregular') format('svg');
  
  } 

  @font-face {
    font-family: 'nexa_light';
    font-weight: 200;
    font-style: light;
    src: url('../fonts/Nexa Light.otf');
  
  
  } 
  

  @font-face {
    font-family: 'cutiveMono_regular';
    font-weight: 500;
    font-style: bold;
    src: url('../fonts/Cutive_Mono/CutiveMono-Regular.ttf');
  
  } 


  $Nexa:'nexa_boldregular', sans-serif;
  $Cutive: 'cutiveMono_regular';
  $NexaLight:'nexa_light',sans-serif;




