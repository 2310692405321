
@import "../styles/variables.scss";
@media (min-width:1400px) {
.rowbg{

        width: 100%;
       padding-right: 24rem;

}

.portrait_md{
    padding-top: 3rem;
    padding-bottom: 7rem;
}

.portrait_xs{
    display: none;
}

.bio_xs{
    display: none;

}
.bio_md{
    padding-left: 18rem;
    padding-top: 11rem;
    h3{
        font-family: $Nexa;
        font-size: 3rem;
    }
    h4{
        font-family:$Overpass;
        color: $dark;
    }
    p{
        padding-bottom: 4rem;
    }
}
}
@media (max-width:1400px) {

    .bio_md{
       padding-top: 4rem;
       padding-left: 4rem;
       padding-right: 4rem;
       padding-bottom: 4rem;
    }



}

