@import "./variables.scss";

body {
  background: $light;
  width: 100%;
  color: $dark;
  font-family: $Overpass;
  font-weight: 300;
  text-align: left;
  overflow-y: scroll; overflow-x: hidden;
  
  
}


.col-12-md-layer{

    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: left;
    background: rgb(255,255,255);
    background: linear-gradient(-135deg, rgba(255,255,255,0) 0%, rgba(39,0,48,0.10407913165266103) 51%, rgba(23,0,31,1) 100%);
   
  
}

.col-6-md-left{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 4rem;
  padding-top: 1.4rem;
  z-index: 2;


}




.rows-6-md{
  border: 2px solid white;
  align-items: flex-end;
}



.col-6-md-right{
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex: 1 200px;
  padding-right: 4rem;
}

.work-col-6-md-right{
  display: flex;

  justify-content: left;
  flex: 1 200px;
}

.blabluss{
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
}
#root {
  height: 100%;
}

a {
  color: #333;
  text-decoration: none;
}

h2{
  font-family: $Nexa;
}

.content {
  max-width: 100%;
  margin: 0;
}

.work{
  margin-top: 4rem;
}

.btn {
  display: block;
  width: 200px;
  padding: 8px 0;
  margin: 10px auto;
  background: #000000;
  border-radius: 4px;
  color: white;
  text-align: center;
}

.bio-right{
  h2{
    font-family: $Nexa;
    margin-bottom: 2rem;
  }
  p.label{
    font-family: $Cutive;

  }
  p{
    font-family: $Overpass;
    font-size: 14pt;
    line-height: 32px;
    font-weight: 200;
  }
    margin-left: 17rem;
    margin-right: 3rem;
    margin-top: 2rem;
}

.text {
   z-index: 10;
   color: $light;
   margin-bottom: 18rem;
   margin-left: 4rem;
   margin-right: 4rem;


 }
 
 .Main {
  position: absolute;
  right: 0;
  width: 100%;
  min-height: 100vh;
  padding-left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 2;
  transition: 0.5s;
}

.text h6 {
  font-size:  1.6em;
  border-color: white;
  font-family: $Cutive;
  text-align: left;
  margin-bottom: 2rem;
 }

.text h3 {
  .stitle{
    font-size:  0.6em;
    margin-bottom: -1.5rem;
}
}
 .text h2 {
   font-size: 5em;
   font-weight: 300;
   text-align: left;
   color: white;
   font-family: $Nexa;

 }

.break{
  content: "\a ";
  white-space: pre;
}
 .parent { 
	display: inline-flex; 
  align-self: left;
  justify-items: left;
  width: 100%;
} 

.vertical-row {
  float:left;
  height:90px;
  width:1px; /* edit this if you want */
  background-color: white;
  margin-left: 3rem;
  margin-right: 3rem;

 } 
 span {
  display: inline;
}
span:before {
  content: "\a ";
  white-space: pre;
  align-items: left;
}
 .text h3 {
  font-size: 2.2em;
  font-weight: 100;
  text-align: left;
  color: white;
  margin-bottom: 2rem;
  
}
 .text h4 {
  font-size: 2.4em;
  font-weight: 100;
  text-align: left;
  color: white;
  margin-top: 2.5rem;
}
 .text p {
   font-size: 1em;
   font-weight: 100;
 }

 .btn_main{
  display: flex;
  justify-content: left;
  text-align: center;
  align-items: left;
 

 }

 .text a{
   cursor: pointer;
   justify-content: center;
   align-content: center;
   text-align: center;
   text-decoration: none;
   text-transform: uppercase;
   letter-spacing: 2px;
   transition: 0.5s;
   padding: 0.75rem 1.25rem;
   margin-top: 10px;
   outline: none;
   border-radius: 0px;
   font-size: 1.2rem;
   font-family: $Overpass;
   background: transparent;
   color: $light;
   border: 1px solid white;
   transition: 0.3s ease all;
   width: 35%;
   margin-top: 4rem;

 }

 .text a:hover{
   letter-spacing:10px;
   color: $light;
 }



.text-description{
  font-family: $Overpass;
  font-weight: 300;
  font-size: 1rem;

}
.text-title {
    font-family: $Nexa;
    font-style: normal;
    padding-top: -40px;
    padding-bottom: 20px;
    font-size: 2rem;
    font-weight: 700;
  }


.text-section{
    padding-top: 3rem;
    font-family: $Cutive;
    font-size: 1.2rem;
    font-weight: 300;
    color: rgb(71, 71, 71);
    margin-bottom: 1rem;

  }

.text-notes{
    font-size: 1rem;
    font-weight: 300;
    opacity: 0.2;

  }

  .text-param{
    font-family: $Overpass;
    font-weight: 500;
    font-size: 1.2rem;
  
  }

  .text-sale{
    font-family: $Overpass;
    font-size: 1rem;
    font-weight: 200;
    padding-right: 2rem;
    padding-bottom: 3rem;
  }

  .col-4{
    font-family: $Overpass;
    font-size: 1rem;
    font-weight:300;
 
  }

  .form-range {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    width: 30%;
  }

  .btn-mint {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-self: center;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: 0px;
    font-size: 1rem;
    font-family: $Overpass;
    background: $primary;
    color: $light;
    border: 1px solid transparent;
    transition: 0.3s ease all;
    width: 170%;


  }

  .btn-mint:hover{
    border-color: $primary;
    background: rgba($primary, 0.1);
    color: $primary;

  }

.btn_reset {
    display: flex;
    justify-content: center;
    cursor: pointer;
    outline: none;
    padding: 0.75rem 1.25rem;
    border-radius: 0px;
    font-size: 1rem;
    font-family: $Overpass;
    letter-spacing: 2px;
    background: white;
    color: $dark;
    border: 1px solid black;
    transition: 0.3s ease all;
    width: 100%;
    align-self: center;
margin-top: -4rem;

  }

.btn_reset:hover{
    border-color: white;
    background: rgba($dark, 0.5);
    color: white;

  }

  .container img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }

.Sketch{
    z-index: -100;
    position: absolute;
    object-fit: cover;
  }

  .align-items-end{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .slider_01{
    display: flex;
    justify-content: first baseline;

  }

  .slider-label{
    font-family: $Poppins;
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 2em;
  }

  .space_bottom{
    padding-bottom: 1em;
  }

  section{
    margin: 0 0px;
}

#main{
  margin-top: -45px;
  display: grid;
  grid-template-columns: 1fr 1fr;

}



.text-banner {
    font-size: 70px;
    font-family: $Nexa;
    font-weight: 550;
    line-height: 1.3;
   
    letter-spacing: 1px;
    justify-content: center;
    text-align: center;
    color: $dark;

    -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 5s; /* Firefox < 16 */
     -ms-animation: fadein 5s; /* Internet Explorer */
      -o-animation: fadein 5s; /* Opera < 12.1 */
         animation: fadein 5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media (max-width:1400px) {

  .text h3 {
    .stitle{
      font-size:  0.2em;
      margin-bottom: -1.5rem;
  }
  }
   .text h2 {
     font-size: 3em;
     font-weight: 300;
     text-align: left;
     color: white;
     font-family: $Nexa;
  
   }


}