@import "../styles/variables.scss";
.dropdown {
    position: absolute;
    width: 100%;
    background-color: rgba($dark, 0.9);

    transform: translate(-50%);
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    z-index: 25;
    @include breakpoint(md){
        transform: none;
        flex-direction: row;
        background: white;
        width: auto;
        height: 100%;
        position: absolute;
      }
}

.dropdown-item{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 300px;
    height: 160;
    padding: 0.2rem;
    margin-left: 11rem;
    margin-right: -2rem;
    transition: background 0.3s;
}

.dropdown-item h3{
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 10px;
}

.dropdown-item p{
    letter-spacing: 2px;
}

@media(max-width: 1400px){
    .dropdown {
        display: none;
        position: absolute;
        display: flex;
        flex-direction: column;
        color: $dark;
        margin: 10px;
        padding-top: 20px;
        top: 60px;
        z-index: 16;
        @include breakpoint(md){
            display: none;
           }
    }
}